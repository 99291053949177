const crypto = require('crypto')

const cache = {}

export function randomColor(str: string = '') {
    if(cache[str])
        return cache[str]
    const seed = crypto
        .createHash('md5')
        .update(str, 'binary')
        .digest('hex')

    // https://stackoverflow.com/questions/3426404/create-a-hexadecimal-colour-based-on-a-string-with-javascript
    let hash = 0, chr
    for (let i = 0; i < seed.length; i++) {
        chr = seed.charCodeAt(i)
        hash = ((hash << 5) - hash) + chr
        hash |= 0 // Convert to 32bit integer
    }

    cache[str]= '#'
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF
        cache[str] += ('00' + value.toString(16)).substr(-2)
    }
    return cache[str]
}
